<template>
    <div class="remove__list">
        <span>
            Select list or chat on the left
        </span>
    </div>
</template>

<script>

    import {EventEmitter} from '../main'

    export default {
        mounted() {
            setTimeout(() => {
                let data = {
                    type: 'no_list',
                    data: {
                        text: 'List does not exist',
                        cancel: 'Close',
                        delete: ''
                    },
                    list: ''
                }
                EventEmitter.$emit('OpenPopup', data)
            }, 100)
        }
    }

</script>